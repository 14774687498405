export const NUMBER_REGEX = /^[0-9\b]+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Zd]/;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(ezcloud)\.io$/;
export const VALID_DATE_MMDDYYYY_REGEX =
  /(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}/;
export const VALID_DATE_DDMMYYYY_REGEX =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
export const NUMBERS_ONLY_REGEX = /^[0-9.,]+$/;
export const CURRENCY_REGEX=/^[0-9]*\.?[0-9]*$/;
export const NUMBERS_AND_SYMBOLS_REGEX = /^[0-9/]+$/;
export const LETTERS_NUMBERS_SYMBOLS_SPACE = /^[a-zA-Z0-9_]*$/;
export const LETTERS_NUMBERS_SYMBOLS = /^[a-zA-Z0-9!@#$&()\\_.+,/\"-]+$/;
export const NUMBERS_SYMBOLS = /^[0-9!@#$&()\\_.+,/\"-]+$/;
export const LETTERS = /^[a-zA-Z]+$/;
export const NUMBERS_DOT_REGEX = /^[0-9]*\.?[0-9]*$/;
export const TIME_MMDDYYYY_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;
export const NUMBER_TWO_DECIMAL=/^[0-9]*\.?[0-9]{0,2}$/;
export const DATE_REGEX=/^\d{2}\/\d{2}\/\d{4}$/;
export const NEGATIVE_NUMBERS=/^-?\d{1,}(,\d{3})*(\.\d+)?$/;