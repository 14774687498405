export const CustomMessage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ["success", "info","error"].includes(type) ? false : true,
    autoHideDuration: ["success", "info","error"].includes(type) && 3000,
  });
};

export const ErrorImage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: true,
    // autoHideDuration: ["success", "info","error"].includes(type) && 3000,
  });
};

export const ErrorTiffImage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: true,
    // autoHideDuration: ["success", "info","error"].includes(type) && 3000,
  });
};

export const ErrorPdf = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: true,
    // autoHideDuration: ["success", "info","error"].includes(type) && 3000,
  });
};

export const ErrorInvalidFiles = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: true,
    // autoHideDuration: ["success", "info","error"].includes(type) && 3000,
  });
};