import {
  Typography,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Checkbox,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { SUPPLIER_RECLARIFICATION ,SAVE_AUDIT} from "../../comman/url";
import { deCryptFun, enCryptFun, getUserId } from "../../comman/function";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import { CustomMessage } from "../../comman/helperfunction";
import API from "../../constants/API";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Autocomplete from '@mui/material/Autocomplete';

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormHelperText from "@mui/material/FormHelperText";
import {
  useNavigate,
} from "react-router-dom";
import { 
  INVOICE_ROUTE,
} from "../../constants/RoutePaths";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  BootstrapDialog: {
    width: "-webkit-fill-available",
  },
  canceldialog: {
    border: "1px solid #2B88F0 !important",
    color: "##2B88F0 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #2B88F0 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: " #2B88F0 !important",
    color: "white !important",
    margin: "8px",
    padding: "8px 16px",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#0c70df !important",
      color: "white !important",
    },
  },
  nativedrop: {
    padding: "16.5px 14px",
  },
  smallseperate: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
}));

let filterInitial = { email: "", firstName: "", lastName: "" };

function SupplierList(props) {
  const { open, CloseToggle, supplierList } = props;
   console.log(supplierList);
  const styleclasses = useStyles();
  const [state, setState] = useState([]);
  let navigate = useNavigate();
  const [value, setValue] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const postData = () => {};
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {},
    //validationSchema,
    onSubmit: (values) => saveFormValues(values),
  });

  const saveFormValues = async () => {
    console.log(value);
   
    const keycongigure = {
      method: "POST",
      url: SUPPLIER_RECLARIFICATION,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: props.invoiceId,
            supplierName: value.supplierName,
            teamId:props.teamId,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      console.log(response);
      CustomMessage("Reclassification sent successfully", "success", enqueueSnackbar);
    } catch (error) {
      console.log("error", error);
    } finally {
      CloseToggle();
      navigate(INVOICE_ROUTE);
    }
  };

  const { firstName = "", lastName = "", email = "" } = values;

  const [isChecked, setIsChecked] = React.useState(
    supplierList.slice().fill(false)
  );

  const toggleCheckboxValue = (index, value) => {
    var indexs = state.indexOf(value);
    if (indexs > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      state.splice(indexs, 1);
    } else {
      setState([...state, value]);
    }
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  //console.log(supplierList);


  const saveAudit = async () => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: props.invoiceId,
            comments: "Reclasification",
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      let l = deCryptFun(response.data);
    } catch (error) {}
  };
  const handleChangeradio = (event) => {
    console.log(event);
    setValue(event.target.value);
  };
  

  return (
    <Dialog
      open={open}
      onClose={CloseToggle}
      PaperProps={{
        sx: {
          maxHeight: 300,
          maxWidth: "75%",
          "&::-webkit-scrollbar": {
            width: "0.5em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A0A3BD",
            outline: "1px solid #A0A3BD",
            borderRadius: "50px",
          },
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        {supplierList && supplierList.length > 0 && (
          <>
           
            <DialogContent maxWidth="xl">
            <FormLabel id="demo-radio-buttons-group-label">List of Suppliers</FormLabel>
              
              {/* {supplierList.map((item, index) => (
                item.supplierName !=null &&
                <>
                  <Grid sx={12} md={12} maxWidth="xl">
                    <FormControl>                   
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"                        
                   
                        name="radio-buttons-group"
                        onChange={handleChangeradio}
                        value={value}
                      >
                        <FormControlLabel
                          value={item.supplierName}
                          control={<Radio />}
                          label={item.supplierName}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ))} */}

     <Autocomplete
    
      id="combo-box-demo"     
      options={supplierList}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      getOptionLabel={(option) => option.supplierName}
      sx={{ width: 300,marginTop:"20px" }}    
      renderInput={(params) => (
        <TextField {...params} />
      )}
      ListboxProps={{
        style: {
          maxHeight: "10rem",
         
        },
      }}
    />



            </DialogContent>
          </>
        )}
        {supplierList.length <= 0 && (
          <DialogTitle>No Suplier found</DialogTitle>
        )}

{supplierList && supplierList.length > 0 && (
        <DialogActions>
          <DialogActions>
            <Button className={styleclasses.canceldialog} onClick={CloseToggle}>
              Cancel
            </Button>
            <Button type="submit" className={styleclasses.submitdialog}>
              Ok
            </Button>
          </DialogActions>
        </DialogActions>
)}
      </form>
    </Dialog>
  );
}

export default SupplierList;
