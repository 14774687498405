import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getTeamID,
  getLoginName,
  getUserId,
} from "../comman/function";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import { NOTIFY_SUPPLIER_URL,SAVE_AUDIT } from "../comman/url";
import API from "../constants/API";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import sendicon from "../images/sendem.png";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import notifytick from "../images/alert icon.png";

const useStyles = makeStyles((theme) => ({
  notifyapproverbtn: {
    margin: "0px 8px !important",
    fontWeight: "bold !important",
    fontSize: "13px !important",
    color: "#3779EC !important",
    border: "1px solid #3779EC !important",
    textTransform: "none !important",
  },
  notifysupplierbtn: {
    fontSize: "14px !important",
    color: "#EC7C37 !important",
    border: "2px solid #EC7C37 !important",
    textTransform: "none !important",
    padding: "6px 23px",
  },
  notifydownloadbtn: {
    margin: "0px 0px 0px 20px !important",
    fontSize: "14px !important",
    backgroundColor: "#EC7C37 !important",
    color: "white !important",
    textTransform: "none !important",
    boxShadow: "none !important",
    padding: "8px 25px",
    "&:hover": {
      backgroundColor: "#D0580F !important",
      color: "white !important",
    },
  },
}));

export default function NotifySupplier(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { suppFlag, CloseToggle, number, email,supplierName,invoiceID,getAudit } = props;
  const styleclasses = useStyles();
  const UserReducer = useSelector((s) => s.UserReducer);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const supplierSubmit = async (values) => {
    console.log(values);
    const config = {
      method: "POST",
      url: NOTIFY_SUPPLIER_URL,
      headers: authHeader(),
      data: {
        // teamId: getTeamID(),
        // supplierEmail: email,
        // invoiceNumber:
        //   values.invoiceNumber === "N/A" ? "" : values.invoiceNumber,
        // requesterEmail: getEmail(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
            supplierEmail: email,
            invoiceNumber:
              values.invoiceNumber === "N/A" ? "" : values.invoiceNumber,
            requesterEmail: getEmail(),
            adminUrl:window.location.origin
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { status, message } = response.data;
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      // Swal.fire(getAlertToast("success", message));
      if(status==="Success")
      {
        saveAudit()
      CustomMessage(
        "Email has been sent to the supplier" ,
        "success",
        enqueueSnackbar
      ); //call toster
      
      CloseToggle();
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // let { message } = data;
      // Swal.fire(getAlert("Error", message));
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // console.log("error data new",  v.message)
        // Swal.fire(getAlert("Error", v.message));
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  const validationSchema = Yup.object().shape({
    // invoiceNumber: Yup.string().required("Required"),
  });
  const saveAudit=async ()=>{
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({

            invoiceId: invoiceID,
            comments: "Notify Supplier",
            actionBy: getUserId(),
            isExceptionResolved: false
          })
        ),
        flutterString: "",
      },
    };
    try{
      const response = await API(config);
      let l = deCryptFun(response.data);
      const { status, message } = JSON.parse(l);
      if(message==="Success"){
        getAudit()
      }
    }
    catch(error){

    }
  }

  const { handleSubmit, handleChange, values, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        invoiceNumber: number === "N/A" ? "" : number,
      },

      onSubmit: (values) => supplierSubmit(values),
    });

  return (
    <Dialog
      style={{ borderRadius: "8px !important" }}
      fullScreen={fullScreen}
      open={suppFlag}
      onClose={CloseToggle}
      aria-labelledby="responsive-dialog-title"
    >
      <form style={{ padding: "0px 15px" }} onSubmit={handleSubmit}>
        {/* <DialogTitle id="responsive-dialog-title"></DialogTitle> */}
        <DialogContent style={{ padding: "25px 14px 15px" }}>
          <Box sx={{ width: "69px", margin: "auto" }}>
            <img style={{ width: "50px" }} src={sendicon} />
          </Box>
          <DialogContentText>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "20px",
                color: "#000",
                mt: 2,
                lineHeight: "35px",
              }}
              variant="h4"
            >
              Would you like to send an email to supplier?
              {/* <br /> {UserReducer.userName} ? */}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "20px 20px 20px" }}>
          <Button
            autoFocus
            onClick={CloseToggle}
            className={styleclasses.notifysupplierbtn}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            autoFocus
            className={styleclasses.notifydownloadbtn}
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
