import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
  Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/system";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Scanner from "../Scanner";
import {
  GET_SUPPLIER_LIST_URL,
  PRE_SIGNED_URL,
  REUPLOAD_URL,
  USER_EMAIL_LIST_URL,
} from "../../comman/url";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSenderEmail,
  getTeamID,
} from "../../comman/function";
import API from "../../constants/API";
import { authHeader, getAlert, getAlertToast } from "../../comman/mainfunction";
import { useParams } from "react-router-dom";
import { INVOICE_ROUTE } from "../../constants/RoutePaths";
import { CustomMessage, ErrorCustomMessage, ErrorImage, ErrorInvalidFiles, ErrorPdf, ErrorTiffImage } from "../../comman/helperfunction";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTheme } from "@mui/material/styles";

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
const FileExtensions = {
  "application/pdf": "pdf",
  "application/PDF": "PDF",
  "image/png": "png",
  "image/PNG": "PNG",
  "image/jpeg": "jpeg",
  "image/JPEG": "JPEG",
  "image/jpg": "jpg",
  "image/JPG": "JPG",
  "image/tiff": "tiff",
  "image/TIFF": "TIFF",
};

const useStyles = makeStyles((theme) => ({
  dashboardcancelbtn: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  BootstrapDialog: {
    width: 650,
  },
  uploaddialog: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    // padding: "8px 16px",
    with: "100px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
    "&:disabled":{
      backgroundColor:"black !important",
      
    }
  },
  uploadbun: {
    border: "1px solid #EC7C37 !important",
    color: "#EC7C37 !important",
    margin: "8px",
    textTransform: "none !important",
    padding: "8px 16px",

    "&:hover": {
      border: "1px solid #EC7C37 !important",
      backgroundColor: "#F6F6F680 !important",
      color: "#EC7C37 !important",
    },
  },
  submitdialog: {
    backgroundColor: "#ec7c37 !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    fontSize: "12px !important",
    border: "1px solid #ec7c37 !important",
    "&:hover": {
      color: "#ec7c37 !important",
      fontSize: "12px !important",
      border: "1px solid #ec7c37 !important",
      backgroundColor: "transparent !important",
      boxShadow: "none !important",
    },
//     "&Mui-disabled":{
// backgroundColor:"green !important"
//     },
  },

  nativedrop: {
    padding: "16.5px 14px",
  },
  dialogins: {
    fontSize: "14px",
    marginTop: "10px",
  },
  dialogCustomizedWidth: {
    maxWidth: "46%",
    overflow: "hidden",
    overflowY: "auto",
    width: "unset",
  },
  Uploadfiledrop: {
    position: "absolute",
    opacity: "0",
    top: "73px",
    left: "40px",
    height: "calc(29% + 4px)",
    width: "calc(93% + 5px)",
    outline: "none",
    textAlignLast: "center",
  },
  divider: {
    width: "700px",
    margin: "auto",
  },
  button: {
    "&:disabled": {
      backgroundColor: 'red !important',
    }
  }
}));

const Input = styled("input")({
  display: "none",
});
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
    borderRadius: "8px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B4B4B4",
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#14142A",
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid #B4B4B4 !important",
    fontSize: 16,
    padding: "16.5px 14px",
    borderRadius: "8px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      backgroundColor: "white",
      border: "1px solid #14142A !important",
      borderRadius: "8px",
    },
  },
}));

// const theme = useTheme();
// const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

const BUCKET_NAME = "inbox-ezcloud123";
var folderName = "dashboard_uploads";
var uploadAPICall = null;

const UploadInvoiceNew = ({ open, handleToggle, successCall, theme }) => {
  const styleclasses = useStyles();
  const handleClose = () => {
    handleToggle();
    // successCall("cancel");
    setUploadOrScan(null);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [uploadOrScan, setUploadOrScan] = useState(null);
const [isUploading,setIsUploading]=useState(false)
  const handleUploadOrScanCancel = () => {
    setSelectedFile();
    setUploadOrScan(null);
  };
  // const handleSetUpload = () => setUploadOrScan("upload");
  const handleSetUpload = () => setIsUploading(true);

  let { invoiceID } = useParams();
  const [emailList, setEmailList] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [cancelFlag, setCancelFlag] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [sourceType, setType] = useState();
  const [reportInvoiceModelFlag, setReportInvoiceModelFlag] = useState(false);
  const handleSetScan = () => {
    openscanHandle();
    setUploadOrScan("scan");
  };

  const openscanHandle = () => {
    setReportInvoiceModelFlag(true);
  };
  const closescanHandler = () => {
    setReportInvoiceModelFlag(false);
    setUploadOrScan(null);
  };
  const constructFileName = (emailId,typeOfFile) => {
    let uniqueKey = `${Date.now()}`;

    let extension = FileExtensions[typeOfFile] || "pdf" || "PDF"

    let fileName = `${uniqueKey}.${extension}`;

    // Upload the file to S3
    let documentName = folderName + "/" + fileName;

    if (emailId) {
      // Mail from folder isolation
      let mail_from_username = emailId.split("@")[0];
      let mail_from_fulldomain = emailId.split("@")[1];
      let mail_from_folder = mail_from_fulldomain.split(".")[0];
      folderName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}`;
      documentName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}/${fileName}`;
    }
    return { documentName, folderName, fileName,typeOfFile };
  };
  const constructFileNameScan = (emailId) => {
    let uniqueKey = `${Date.now()}`;

    let extension = FileExtensions[sourceType] || "pdf" || "PDF" ;

    let fileName = `${uniqueKey}.${extension}`;

    // Upload the file to S3
    let documentName = folderName + "/" + fileName;

    if (emailId) {
      // Mail from folder isolation
      let mail_from_username = emailId.split("@")[0];
      let mail_from_fulldomain = emailId.split("@")[1];
      let mail_from_folder = mail_from_fulldomain.split(".")[0];
      folderName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}`;
      documentName = `${mail_from_folder}/${mail_from_username}_${mail_from_folder}/${fileName}`;
    }
    return { documentName, folderName, fileName };
  };

  const setSourceType =(type)=>{setType(type); }

  // const uploadInvoice = async(e) => {
   
  //   let files = e.target.files;
  //   const formData = new FormData();
  //   console.log(e.target.files);
    
  //   for  (let i = 0; i < files.length; i++) {
  //     if (isSubmitting) {
  //       return;
  //     }

  //     const filesize = Math.round(files[i].size / 1024);
  //     const { type } = files[i];
  //     setSourceType(type);
  //     if (
  //       type === "image/png" ||
  //       type === "image/jpeg" ||
  //       type === "image/jpg"
  //     ) {
  //       if (filesize >= 10367) {
  //         CustomMessage(
  //           "File size exceeds. It should follow the below criteria, it must not exceed 10MB",
  //           "error",
  //           enqueueSnackbar
  //         );

  //         return;
  //       }
  //     }
  //     if (type === "image/tiff") {
  //       if (filesize >= 518350) {
  //         CustomMessage(
  //           "File size exceeds. It should follow the below criteria, it must not exceed 500MB",
  //           "error",
  //           enqueueSnackbar
  //         );

  //         return;
  //       }
  //     }
  //     if (type === "application/pdf") {
  //       if (filesize >= 30720) {
  //         CustomMessage(
  //           "File size exceeds. It should follow the below criteria, it must not exceed 30MB",

  //           "error",
  //           enqueueSnackbar
  //         );

  //         return;
  //       }
  //     }

  //     if (
  //       type === "application/pdf" ||
  //       type === "image/png" ||
  //       type === "image/jpeg" ||
  //       type === "image/jpg" ||
  //       type === "image/tiff"
  //     ) {
  //       //   const selectedFiles=e.target.files[0];
  //       formData.append(`files`, files[i]);

  //     } else {
  //        setTimeout(()=>{CustomMessage( `Invalid File ${files[i].name} . Please upload PDF,PNG,JPEG,TIFF file format only.`, "error", enqueueSnackbar )}, 3000*i);
  //     //  await CustomMessage( `Invalid File ${files[i].name} . Please upload PDF,PNG,JPEG,TIFF file format only.`, "error", enqueueSnackbar );
  //     } }
  //   if(Array.from(formData.keys()).length > 0){
  //     handleSetUpload()
  //     UploadInvoiceSubmit(formData)
  //   }

  // };

  const uploadInvoice = async (e) => {
    if (!isSubmitting) {
      let files = e.target.files;
      const formData = new FormData();
      let errorFiles = {
        exceededFiles: "",
        exceededImages: "",
        exceededTiffImages: "",
        exceededPdf: "",
        invalidFiles: ""
      };

      for (let i = 0; i < files.length; i++) {
        const filesize = Math.round(files[i].size / 1024);
        if (files[i].type === "image/png" || files[i].type === "image/PNG" || files[i].type === "image/jpeg" || files[i].type === "image/JPEG" || files[i].type === "image/jpg" || files[i].type === "image/JPG") {
          if (filesize >= 10367) {
            errorFiles.exceededImages += (files[i].name) + ", ";
          }
          else formData.append(`files`, files[i]);
        }
        else if (files[i].type === "image/tiff" || "image/TIFF" ) {
          if (filesize >= 518350) {
            errorFiles.exceededTiffImages += (files[i].name) + ", ";
          }
          else formData.append(`files`, files[i]);
        }
        else if (files[i].type === "application/pdf" || "application/PDF" ) {
          if (filesize >= 30720) {
            errorFiles.exceededPdf += (files[i].name) + ", ";
          }
          else formData.append(`files`, files[i]);
        }
        else {
          errorFiles.invalidFiles += (files[i].name) + ", ";
        }
      }

      for (let [key, value] of Object.entries(errorFiles)) {
        if (value !== "") {
          if (key === "exceededImages") {
            ErrorImage(
              `File size exceeded for ${(errorFiles.exceededImages).slice(0,-2)}. It should follow the below criteria, it must not exceed 10MB`,
              "error",
              enqueueSnackbar
            );
          }
          if (key === "exceededTiffImages") {
            ErrorTiffImage(
              `File size exceeded for ${(errorFiles.exceededTiffImages).slice(0,-2)}. It should follow the below criteria, it must not exceed 500MB`,
              "error",
              enqueueSnackbar
            );
          }
          if (key === "exceededPdf") {
            ErrorPdf(
              `File size exceeded for ${(errorFiles.exceededPdf).slice(0,-2)}. It should follow the below criteria, it must not exceed 30MB`,
              "error",
              enqueueSnackbar
            );
          }
          if (key === "invalidFiles") {
            ErrorInvalidFiles(
              `Invalid File: ${(errorFiles.invalidFiles).slice(0,-2)} - Only PDF, PNG, JPG/JPEG, and TIF/TIFF files are supported.`,
              "error",
              enqueueSnackbar);
          }
        }
      }

      if (Array.from(formData.keys()).length > 0) {
        handleSetUpload()
        UploadInvoiceSubmit(formData)
      }
    }
  };



  const UploadInvoiceSubmit = async (selectedFiles) => {

    console.log(selectedFiles, "selectedFile", uploadOrScan);

    
    if (cancelFlag) {
      setCancelFlag(false);
      return;
    }
 
    if (!selectedFiles && uploadOrScan != "scan") {
      CustomMessage( "Please upload file!", "error", enqueueSnackbar );
    } else if (!selectedFile && uploadOrScan == "scan") {
      CustomMessage( "Please upload file1!","error",enqueueSnackbar);
    } 
    // else{
    //    for (let [key, value] of selectedFiles.entries()) {
    //      console.log(key, ':', value.name);
    //      }
    //   // console.log(selectedFiles)
    // }
    else {
       for (let [key, value] of selectedFiles.entries()) {
      //     console.log(key, ':', value.name);
      
      CustomMessage(`Uploading  ${value.name}`,"success",enqueueSnackbar);
      setIsSubmitting(true);
      
      let s3FileUrl = "";
      let emailId = invoiceID ? "" : getEmail();
      const { documentName, folderName, fileName ,typeOfFile} = constructFileName(emailId,value.type);
      try {
        const config = {
          method: "POST",
          url: PRE_SIGNED_URL,
          headers: authHeader(),
          data: {
            // name: documentName,
            // type: sourceType,
            webString: enCryptFun(
              JSON.stringify({
                name: documentName,
                type: typeOfFile,
              })
            ),
            flutterString: "",
          },
        };

        const response = await API(config);
        //const { status, url } = response.data;
        let l = deCryptFun(response.data);
        const { status, url } = JSON.parse(l);
        if (status === "Success") {
          uploadAPICall = axios.CancelToken.source();

          let options = {
            headers: {
              "Content-Type": typeOfFile,
            },
            onUploadProgress: function (progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadingPercentage(percentCompleted);
            },
            cancelToken: uploadAPICall.token,
          };

          await axios.put(url, value, options);

          s3FileUrl =
            "https://" + BUCKET_NAME + ".s3.amazonaws.com/" + documentName;
        }
      } catch (error) {
        console.log(error);
        console.log(JSON.stringify(error?.response));
        alert("Error uploading file");
        setIsSubmitting(false);
        setUploadingPercentage(0);
        setSubmitting(false); // formik
        return;
      }
      if (!s3FileUrl) {
        setIsSubmitting(false);
        return;
      }
      // making api call to lambda
      console.log("supplier name on upload email .......", values.email);
      try {
        const option = {
          method: "POST",
          url: REUPLOAD_URL,
          params: {
            action: !invoiceID ? "Insert" : "Update",
            uploadBy: getRole() === "Supplier" ? "Supplier" : "Customer",
            invoiceId: invoiceID,
            s3FileUrl: s3FileUrl,
            fileType:FileExtensions[typeOfFile],
            emailId: emailId,
            documentName: documentName,
            fileName: fileName,
            folderName: folderName,
            //supplierName: values.email,
            supplierName:"",
            invoiceSource: "Web",
            toEmailId: invoiceID
              ? ""
              : getRole() !== "Supplier"
              ? getSenderEmail()
              : values.email,
          },
          headers: { ...authHeader(), "Content-Type": "application/json" },
        };
        let response = await API(option);
        const { success, errorMsg = "" } = response.data;
        if (success) {
          CustomMessage( `${value.name} uploaded successfully`, "success", enqueueSnackbar );
      
          setValues((va) => ({ ...va, email: "" }));

          // handleClose();
          // successCall("");
        } else {
          CustomMessage( errorMsg, "error", enqueueSnackbar );
        }
      } catch (error) {
        CustomMessage( "Error", "error", enqueueSnackbar );
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
       }
       setIsUploading(false)
    }
   
  };

  const submitScannedInvoice = async () => {

    console.log(selectedFile, "selectedFile", uploadOrScan);

     if (!selectedFile && uploadOrScan == "scan") {
      CustomMessage( "Please upload file1!","error",enqueueSnackbar);
    } 
  
    else {
      CustomMessage(`Uploading ..`,"success",enqueueSnackbar);
      setIsSubmitting(true);
      
      let s3FileUrl = "";
      let emailId = invoiceID ? "" : getEmail();
      const { documentName, folderName, fileName } = constructFileNameScan(emailId);
      try {
        const config = {
          method: "POST",
          url: PRE_SIGNED_URL,
          headers: authHeader(),
          data: {
            // name: documentName,
            // type: sourceType,
            webString: enCryptFun(
              JSON.stringify({
                name: documentName,
                type: sourceType,
              })
            ),
            flutterString: "",
          },
        };

        const response = await API(config);
        //const { status, url } = response.data;
        let l = deCryptFun(response.data);
        const { status, url } = JSON.parse(l);
        if (status === "Success") {
          uploadAPICall = axios.CancelToken.source();

          let options = {
            headers: {
              "Content-Type": sourceType,
            },
            onUploadProgress: function (progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadingPercentage(percentCompleted);
            },
            cancelToken: uploadAPICall.token,
          };

          await axios.put(url, selectedFile, options);

          s3FileUrl =
            "https://" + BUCKET_NAME + ".s3.amazonaws.com/" + documentName;
        }
      } catch (error) {
        console.log(error);
        console.log(JSON.stringify(error?.response));
        alert("Error uploading file");
        setIsSubmitting(false);
        setUploadingPercentage(0);
        setSubmitting(false); // formik
        return;
      }
      if (!s3FileUrl) {
        setIsSubmitting(false);
        return;
      }
      // making api call to lambda
  
      try {
        const option = {
          method: "POST",
          url: REUPLOAD_URL,
          params: {
            action: !invoiceID ? "Insert" : "Update",
            uploadBy: getRole() === "Supplier" ? "Supplier" : "Customer",
            invoiceId: invoiceID,
            s3FileUrl: s3FileUrl,
            fileType: FileExtensions[sourceType],
            emailId: emailId,
            documentName: documentName,
            fileName: fileName,
            folderName: folderName,
          //  supplierName: values.email,
            supplierName:"",
            invoiceSource: "Web",
            toEmailId: invoiceID
              ? ""
              : getRole() !== "Supplier"
              ? getSenderEmail()
              : values.email,
          },
          headers: { ...authHeader(), "Content-Type": "application/json" },
        };
        let response = await API(option);
        const { success, errorMsg = "" } = response.data;
        if (success) {
          CustomMessage(
            " uploaded successfully",

            "success",
            enqueueSnackbar
          );

          setSelectedFile();
          // setUploadOrScan();
          setValues((va) => ({ ...va, email: "" }));

          // handleClose();
          // successCall("");
        } else {
          CustomMessage(
            errorMsg,

            "error",
            enqueueSnackbar
          );
        }
      } catch (error) {
        CustomMessage(
          "Error",

          "error",
          enqueueSnackbar
        );
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
       }
    
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().when([], {
      is: () => !invoiceID && getRole() === "Supplier",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const {
    handleSubmit,
    handleChange,
    setSubmitting,
    values,
    handleBlur,
    errors,
    setValues,
    touched,
  } = useFormik({
    initialValues: { email: "" },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => UploadInvoiceSubmit(values),
  });
  console.log(errors, values, !invoiceID && getRole() === "Supplier");
  const getEmailList = async () => {
    const config = {
      method: "GET",
      url:
        getRole() === "Supplier" ? USER_EMAIL_LIST_URL : GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params:
        getRole() === "Supplier"
          ? {
              webString: enCryptFun(
                JSON.stringify({
                  entityType:
                    getRole() === "Supplier" ? "Customer" : "Supplier",
                  offset: 0,
                  count: 1000,
                  teamId: getTeamID(),
                })
              ),
              flutterString: "",
            }
          : {
              webString: enCryptFun(
                JSON.stringify({
                  supplierId: getEmail(),
                })
              ),
              flutterString: "",
            },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);

      setEmailList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        console.log("error data new", v.message);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };
  useEffect(() => {
    getEmailList();
  }, []);
  const [cancelModalflag, setcancelModalflag] = useState(false);

  const onClickSubmittingAPICancel = () => {
    setcancelModalflag(true);
  };

  const cancleToggle = () => {
    setcancelModalflag(false);
  };
  const cancelInvoice = () => {
    if (uploadAPICall) {
      uploadAPICall.cancel("Upload cancelled");
      setUploadingPercentage(0);
      setCancelFlag(true); // formik wierd  - retries upload on cancelled api. so setting a flag and returning submit when true
      setIsSubmitting(false); // usestate
      setSubmitting(false); // formik
      setcancelModalflag(false);
    }
  };

 

  const handleBlobChange = (blob) => {
    console.log(blob, "hi");

    // handleFileChange(blob)
    setSelectedFile(blob);
  };
  let name1 = "No file Selected !";

  if (selectedFile) {
    const { name } = selectedFile;
    name1 = name;
  }

  const cancleInvoice = () => {
    setSelectedFile();
    name1 = "";
    setValues((va) => ({ ...va, email: "" }));
    handleClose();
  };
  const onSupplierChange = (e) => {
    if (e.target.value !== "") {
      handleChange(e);
    }
  };

  return (
    <>
      <>
        {" "}
        {!uploadOrScan && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <label htmlFor="contained-button-file">
              <Input
                onChange={uploadInvoice}
                id="contained-button-file"
                multiple
                disabled={isUploading}
                // disabled={isSubmitting}
                type="file"
                accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,application/PDF,image/PNG,image/JPEG,image/JPG,image/TIFF"
              />
              <Button
                variant="outlined"
                className={styleclasses.uploaddialog}
                component="span"
                disabled={isUploading}
              >
                Upload
              </Button>
            </label>
          </Stack>
        )}
        <label style={{ margin: "6px" }} htmlFor="contained-button-file">
          <Button
            variant="contained"
            // disabled={isSubmitting}
            onClick={handleSetScan}
            disabled={uploadOrScan=="upload"?true:false}
            className={styleclasses.submitdialog}
            component="span"
          >
            Scan
          </Button>
        </label>
      </>

      {!isSubmitting && uploadOrScan === "scan" && reportInvoiceModelFlag && (
        <>
          <Scanner
            handleBlobChange={handleBlobChange}
            openModal={openscanHandle}
            closeModal={closescanHandler}
            submitInvoice={submitScannedInvoice}
          />
        </>
      )}
    </>
  );
};

export default UploadInvoiceNew;
