import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { Divider } from "@mui/material";
import { INVOICEPREVIEW_ROUTE } from "../constants/RoutePaths";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  Link,
  useParams,
  useHistory,
  Prompt,
  useNavigate,
} from "react-router-dom";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import { ACCESS_S3_FILE } from "../comman/url";
import API from "../constants/API";
import Axios from "axios";
import { NoRecordFound } from "../components/NoRecordFound";
import { Badge } from "reactstrap";
import { statusStyleConfig } from "../constants/HelperConstant";
import Loader from "../comman/Loader";

const useStyles = makeStyles((theme) => ({
  tableheadrow: {
    background: "#F0F1F5 !important",
    color: "#14142A !important",
  },
  tablecellrow: {
    borderRight: "1px solid black",
  },
  paginationrow: {
    placeContent: "flex-start",
    display: "flex",
  },
  paginationitem: {
    placeContent: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  paginationnum: {
    display: "flex",
    alignItems: "center",
  },
  tableContainerbg: {
    // background: "white !important",
    overflowX: "auto",
    // boxShadow:
    //   "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important",
    // marginBottom: "16px !important",
    // borderRadius: "12px !important",
    overflow: "hidden",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),

  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "invoicenumber",
    numeric: true,
    disablePadding: false,
    label: "Invoice Number",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Supplier Name",
  },

  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "currency",
    numeric: true,
    disablePadding: false,
    label: "Currency",
  },
  {
    id: "invoicestatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { classes } = props;

  const styleclasses = useStyles();
  return (
    <TableHead className={styleclasses.tableheadrow}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: 550 }}
            // className="custom-border"
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              disabled={true}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <Divider orientation="vertical" variant="middle" flexItem />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default function DashboardInvoiceTable(props) {
  var fileDownload = require("js-file-download");
  const { invoiceDataList = [], loading } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const styleclasses = useStyles();
  let navigate = useNavigate();

  const validationStatusConfig = {
    "Not Verified": (
      <LightTooltip
        title="This invoice has not passed the validation check"
        placement="top"
      >
        <Button
          className={styleclasses.infobutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<ClearSharpIcon />}
        ></Button>
      </LightTooltip>
    ),
    Valid: (
      <LightTooltip
        title="This invoice has passed invoice validation checks"
        placement="top"
      >
        <Button
          className={styleclasses.correctbutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<CheckRoundedIcon />}
        ></Button>
      </LightTooltip>
    ),
    "Doesn't exist in ERP": (
      <LightTooltip title="Doesn't exist in ERP" placement="top">
        <Button
          className={styleclasses.cancelbutton}
          classes={{ startIcon: styleclasses.startICon }}
          startIcon={<InfoOutlinedIcon />}
        ></Button>
      </LightTooltip>
    ),
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const downloadPdf = async (file, invoiceNum) => {
    console.log("fileName", file);
    if (file) {
      const config = {
        method: "POST",
        url: ACCESS_S3_FILE,
        data: { filePath: file },
        headers: authHeader(),
      };
      try {
        const response = await API(config);
        const { status, url } = response.data;

        if (status === "Success") {
          // setDownloadURL(url);

          Axios.get(url, {
            responseType: "blob",
          }).then((res) => {
            fileDownload(
              res.data,
              invoiceNum != "N/A"
                ? `invoice${invoiceNum}.pdf` || `invoice${invoiceNum}.PDF`
                : `${Date.now()}.pdf` ||`${Date.now()}.PDF`
            );
          });
        }
      } catch (error) {
        let errorObj = Object.assign({}, error);
        let { data } = errorObj.response;
        let { message } = data;
        Swal.fire(getAlertToast("Error", message));
      }
    } else {
      Swal.fire(getAlertToast("error", "No file is found!"));
    }
  };
  const handleClick = (event) => {
    const invoiceID = event.currentTarget.dataset.id;

    let targetInvoice = invoiceDataList.filter((el) => {
      return invoiceID == el.invoiceId;
    });
    console.log("targetInvoice", targetInvoice);
    const status = event.currentTarget.dataset.status;
    if (status === "Initializing") {
      // Swal.fire(getAlert("error", "Invoice is being processed, please wait."));
      Swal.fire({
        title: "Invoice is being processed, Do you want to download the file?",
        icon: "Info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("download", targetInvoice[0].filePath);
          downloadPdf(
            targetInvoice[0].filePath,
            targetInvoice[0].invoiceNumber
          );
        }
      });
    } else if (status === "Reprocessing") {
      Swal.fire(getAlert("error", "Invoice is being processed, please wait."));
    } else {
      // window.open(`${INVOICE_DETAILS_ROUTE}/${invoiceId}`);
      navigate(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`);
      //window.open(`${INVOICEPREVIEW_ROUTE}/${invoiceID}`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  //Api Function
  console.log(invoiceDataList);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          // mb: 2,
          borderRadius: "16px",
          height: "833px",
          overflowY: "auto",
          overflowX: "auto",
          boxShadow: "2px 2px 20px #00000012",
          // background: "transparent !important",
          // boxShadow: "none !important",
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          className={styleclasses.tableContainerbg}
          // sx={{
          //   background: "white !important",
          //   boxShadow: "2px 2px 20px #00000012",
          //   borderRadius: "16px",
          // }}
        >
          <Table
            aria-labelledby="tableTitle"
            // sx={{ height: "833px" }}
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {loading && (
                <TableRow sx={{ height: "775px" }}>
                  <TableCell align="center" colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!loading && invoiceDataList.length === 0 && (
                <TableRow sx={{ height: "775px" }}>
                  <TableCell align="center" colSpan={7}>
                    <NoRecordFound />
                  </TableCell>
                </TableRow>
              )}

              {!loading &&
                stableSort(invoiceDataList, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((datalist, index) => {
                    const {
                      dueAmount,
                      
                      invoiceAmount,
                      invoiceNumber,
                      invoiceCurrency,
                      status,
                      invoiceId,
                      companyName,
                      name,
                      senderEmail,
                      supplierStatus,
                      invoiceStatus,
                      invoicePOStatus,
                      receiverEmail,
                      supplierCompanyName,
                      isResubmited,
                    } = datalist;

                    const isItemSelected = isSelected(invoiceId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event)}
                        data-id={invoiceId}
                        data-status={status}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={datalist.invoiceId}
                        selected={isItemSelected}
                        sx={{ height: "77.5px" }}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {["NaN", "N/A", ""].includes(invoiceNumber)
                            ? "-"
                            : invoiceNumber}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {["NaN", "N/A", ""].includes(name) ? "-" : name}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {/* {invoiceAmount?invoiceAmount:""} */}
                          {["NaN", "N/A", ""].includes(dueAmount)
                            ? "-"
                            : dueAmount}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {["NaN", "N/A", ""].includes(invoiceCurrency)
                            ? "-"
                            : invoiceCurrency}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 450, fontSize: 14 }}
                        >
                          {" "}
                          <Badge color={statusStyleConfig[status]}>
                            {status}
                          </Badge>
                        </TableCell>
                      </TableRow>
                    );
                  })}

              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
